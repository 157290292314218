import * as React from "react";
import "./styles.css";

import Overlay from "./components/Overlay";
//import BackgroundSlideshow from "./components/Slideshow";
import Logo from "./components/Logo";
import BackgroundGrid from "./components/Grid";
import styles from "./App.module.css";

function App() {
  return (
    <div className={styles["app-container"]}>
      <div className={styles["overlay-container"]}>
        <Logo className={styles["overlay-logo"]} />
        <Overlay
          openDate="October 22, 2024"
          closeDate="October 31, 2024"
          storeURL="https://bsnteamsports.com/shop/ynAkdfgyXL"
          storeAccessCode="ynAkdfgyXL"
        />
      </div>
      <div className={styles["background-container"]}>
        {/* <BackgroundSlideshow /> */}
        <BackgroundGrid />
      </div>
    </div>
  );
}

export default App;
