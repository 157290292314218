import { useState, useEffect /*, useRef */ } from "react";
import ClipboardCopy from "./ClipboardCopy";
import styles from "./Overlay.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function Overlay({
  openDate,
  closeDate,
  storeURL,
  storeAccessCode,
}) {
  const [isStoreOpen, setIsStoreOpen] = useState(false);
  const [isStorePast, setIsStorePast] = useState(false);
  const [day, updateDay] = useState();

  const closeDateDisplay = closeDate.substr(0, closeDate.indexOf(","));
  const openDateDisplay = openDate.substr(0, openDate.indexOf(","));
  //  const [sec, updateSec] = useState(null);

  function updateCountdown(s) {
    updateDay(Math.ceil(s / (1000 * 60 * 60 * 24)));
  }

  function visitStore() {
    window.location.href = storeURL;
  }

  // use this interval code if we want to get more detailed that days until store is open
  // useEffect(() => {
  //   let clockInterval = setInterval(() => {
  //     //console.log(endDate);

  //     let openTime = new Date(openDate).getTime();
  //     let closeTime = new Date(closeDate).getTime();
  //     let nowTime = new Date().getTime();

  //     if (openTime > nowTime) {
  //       setIsStoreOpen(false);
  //       updateCountdown(openTime - nowTime); //Future date - current date
  //     } else {
  //       setIsStoreOpen(true);
  //       updateCountdown(closeTime - nowTime);
  //     }
  //   }, 1000);
  //   return () => {
  //     clearInterval(clockInterval);
  //   };
  // }, [openDate, closeDate]);

  useEffect(() => {
    // console.log("call");

    let openTime = new Date(openDate);
    let closeTime = new Date(closeDate);
    let beginOfStore = new Date(openTime.setHours(0, 0, 0, 0));
    let endOfStore = new Date(closeTime.setHours(24, 0, 0, 0));
    let nowTime = new Date();
    //.setHours(0, 0, 0, 0);

    console.log(beginOfStore);
    console.log(nowTime);
    //console.log(endOfDay);

    //let localOpenTime = beginOfStore.toLocaleDateString("en-US", {
    //timeZone: "America/New_York",
    //});

    // let endOfDay = closeDateDO.setHours(24, 0, 0, 0);
    //let localCloseTime = endOfStore.toLocaleDateString("en-US", {
    //timeZone: "America/New_York",
    //});
    // let openTime = new Date(openDate);
    // let closeTime = new Date(closeDate);
    //let localNowTime = nowTime.toLocaleDateString("en-US", {
    //timeZone: "America/New_York",
    //});

    if (nowTime >= beginOfStore) {
      if (nowTime > endOfStore) {
        console.log("Store has closed");
        setIsStoreOpen(false);
        setIsStorePast(true);
        updateCountdown(null);
      } else {
        console.log("Store is open");
        setIsStoreOpen(true);
        setIsStorePast(false);
        updateCountdown(closeTime - nowTime);
      }
    } else {
      setIsStoreOpen(false);
      setIsStorePast(false);
      console.log("Exclusive Store will open in the future");
      updateCountdown(openTime - nowTime); //Future date - current date
    }
  }, [openDate, closeDate]);

  return (
    <>
      <div className={styles["countdown-header"]} id="dayHandle" key="day">
        {day
          ? //day is not being set on same day
            `Exclusive Store ${
              !isStoreOpen ? "Opens" : "Closes"
            } in ${day} Day${day > 1 ? `s` : ``}`
          : isStorePast
          ? `Restocking`
          : `--`}
      </div>
      <div className={styles["countdown-subheader"]}>
        {!isStoreOpen
          ? isStorePast
            ? `The exclusive store will be open again soon. Please follow us on Instagram or Facebook for updates`
            : `The exclusive store will be open for orders from ${openDateDisplay} through ${closeDateDisplay}.`
          : `The exclusive store is open through ${closeDateDisplay}.`}
      </div>
      {isStoreOpen ? (
        /*storeAccessCode*/ <>
          <div className={styles["countdown-text"]}>
            Please click the button below to visit the store. Once there, you
            may be asked to enter the code below.
            <span className={styles["store-code"]}>
              {storeAccessCode}
              <ClipboardCopy copyText={storeAccessCode} />
            </span>
          </div>
          <div className={styles["store-button"]} onClick={visitStore}>
            VISIT STORE{" "}
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              className={styles["store-button-icon"]}
            />
          </div>
        </>
      ) : (
        <div className={styles["basic-store-header"]}>
          Looking for something now?
        </div>
      )}
      <div className={styles["countdown-text"]}>
        The exclusive store may only be open periodically thoughout the year,
        <br />
        but you can always order gear at:
        <br />
        <a
          className={styles["basic-store-link"]}
          href="https://sideline.bsnsports.com/schools/pennsylvania/media/unlimited-academy"
        >
          https://sideline.bsnsports.com/schools/pennsylvania/media/unlimited-academy
        </a>
      </div>
    </>
  );
}
